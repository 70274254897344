import React from 'react';
//gatsby
import { useStaticQuery, graphql } from 'gatsby';
//styles
import styled from 'styled-components';
//components
import Seo from '../components/seo';
import Layout from '../components/layout';
import Header from '../components/header';
import Footer from '../components/footer';
import Intro from '../components/intro';
import CaseStudies from '../components/caseStudies';

//styled
const HomeWrap = styled.section`
  //position: relative;
`;

const HomeContent = styled.div`
  //position: relative;
`;

const Home = () => {

  //graphql query
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteTitle
          email
        }
      }
    }
  `);

  return (
    <React.Fragment>
      <Header data={data.site.siteMetadata} />
      <Layout>
        <Seo title={data.site.siteMetadata.siteTitle} />
        <HomeWrap data-scroll-section>
          <HomeContent>
            <Intro />
            <CaseStudies/>
          </HomeContent>
          <Footer data={data.site.siteMetadata} />
        </HomeWrap>
      </Layout>
    </React.Fragment>
  );
};

export default Home;