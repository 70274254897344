import React, { useContext } from 'react';
//context
import { GlobalContext } from '../context/context';
//gatsby
import { Link, useStaticQuery, graphql } from 'gatsby';
//gatsby image
import { GatsbyImage, getImage } from "gatsby-plugin-image";
//style
import styled from 'styled-components';
import { between, rem } from 'polished';
import vars from './varss';
//components
import Container from './container';
//masonry
import Masonry from 'react-masonry-css';
//framer-motion
import { motion } from 'framer-motion';

const CaseStudiesWrap = styled.section`
  position: relative;
`;

const CaseStudiesList = styled(motion.div)`
  .masonry-grid {
    display: flex;
    margin-left: ${rem('-20px')}; /* gutter size offset */
    width: auto;
  }
  .masonry-grid_column {
    padding-left: ${rem('20px')}; /* gutter size */
    background-clip: padding-box;
  }
`;

const CaseStudyItem = styled(motion.div)`
  margin-bottom: ${rem('80px')};
  position: relative;
  overflow: hidden;
  @media (max-width: ${vars.media.lgMax}) {
    margin-bottom: ${between('40px', '80px')};
  }
  > a {
    display: block;
    text-decoration: none;
    &:hover {
      h2 {
        &::before {
          transform:scale(1);
          transform-origin: left center;
        }
      }
      img {
        transform: scale(1.08);
      }
    }
  }
  .gatsby-image-wrapper {
    margin-bottom: ${rem('20px')};
    @media (max-width: ${vars.media.lgMax}) {
      margin-bottom: ${between('12px', '20px')};
    }
    img {
      transition: all 0.8s ease-out;
    }
  }
  h2 {
    font-size: ${rem('30px')};
    line-height: 1;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: normal;
    margin-bottom: ${rem('8px')};
    display: inline-block;
    position: relative;
    overflow: hidden;
    @media (max-width: ${vars.media.lgMax}) {
      margin-bottom: ${between('4px', '8px')};
    }
    &::before {
      content: "";
      display: block;
      background-color: #000;
      transition: ${vars.transitions.hover3};
      transform: scaleX(0);
      transform-origin: right center;
      margin: auto;
      height: 1px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }
    @media (max-width: ${vars.media.lgMax}) {
      font-size: ${between('24px', '30px')};
    }
  }
  p {
    font-size: ${rem('18px')};
    line-height: 1.11;
    font-weight: 300;
    @media (max-width: ${vars.media.lgMax}) {
      font-size: ${between('16px', '18px')};
    }
    span {
      font-weight: normal;
      @media (max-width: ${vars.media.smMax}) {
        display: block;
      }
    }
    em {
      display: inline-block;
      font-style: normal;
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  .cover {
    background-color: white;
    position: absolute;
    margin: auto;
    top: -10%;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

const CaseStudies = () => {

  //graphql query
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(sort: {fields: frontmatter___id, order: ASC}) {
        nodes {
          id
          frontmatter {
            id
            title
            subtitle
            slug
            thumb {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  `);

  //case studies
  const caseStudies = data.allMarkdownRemark.nodes;

  const breakpointColumnsObj = {
    default: 2,
    767: 1
  };

  //use context
  const [context] = useContext(GlobalContext);

  const Fade = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: {
        ease: 'easeOut',
        duration: 1,
        delay: 1
      }
    }
  };


  return (
    <CaseStudiesWrap>
      <Container>
        <CaseStudiesList animate={context.loaded ? 'animate' : 'initial'} variants={Fade}>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="masonry-grid"
            columnClassName="masonry-grid_column">
            {caseStudies.map((item) => (
              <CaseStudyItem key={item.id}>
                <Link to={item.frontmatter.slug}>
                  <GatsbyImage
                    image={getImage(item.frontmatter.thumb)}
                    alt={item.frontmatter.title}
                  />
                  <h2>{item.frontmatter.title}</h2>
                  <p dangerouslySetInnerHTML={{ __html: `${item.frontmatter.subtitle}` }}></p>
                </Link>
              </CaseStudyItem>
            ))}
          </Masonry>
        </CaseStudiesList>
      </Container>
    </CaseStudiesWrap>
  );
};

export default CaseStudies;