import React, { useContext } from 'react';
//context
import { GlobalContext } from '../context/context';
//style
import styled, { css } from 'styled-components';
import { between, rem, rgba } from 'polished';
import vars from './varss';
//components
import Container from './container';
//grid
import { Row, Col } from 'styled-bootstrap-grid';
//framer-motion
import { motion } from 'framer-motion';

const IntroWrap = styled(motion.section)`
  position: relative;
  padding-top: ${rem('200px')};
  padding-bottom: ${rem('80px')};
  @media (max-width: ${vars.media.lgMax}) {
    padding-top: ${between('120px', '200px')};
    padding-bottom: ${between('40px', '80px')};
  }
`;

const IntroText = styled(motion.h1)`
  position: relative;
  font-size: ${rem('50px')};
  line-height: 0.92;
  font-weight: 300;
  transition: ${vars.transitions.hover2};
  text-transform: uppercase;
  @media (max-width: ${vars.media.lgMax}) {
    font-size: ${between('38px', '50px')};
  }
  span {
    display: block;
  }
  /* active */
  ${props => props.active && css`
    color: ${rgba('#fff', 0.1)};
  `}
`;

const IntroCompanies = styled(motion.div)`
  position: relative;
  font-size: ${rem('24px')};
  line-height: 1.33;
  font-weight: 300;
  transition: ${vars.transitions.hover2};
  @media (max-width: ${vars.media.lgMax}) {
    font-size: ${between('20px', '24px')};
  }
  span {
    font-weight: 300;
  }
  /* active */
  ${props => props.active && css`
    color: ${rgba('#fff', 0.1)};
  `}
`;

//animation
const FadeUp = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      ease: "easeOut",
      duration: 1,
      delay: 0.5,
    }
  }
};

const Intro = () => {

  //use context
  const [context] = useContext(GlobalContext);

  return (
    <IntroWrap animate={context.loaded ? 'animate' : 'initial'} variants={FadeUp}>
      <Container>
        <Row>
          <Col md={6}>
            <IntroText>
              <span>Jakub Reis ~</span>
              Digital Product Designer
            </IntroText>
          </Col>
          <Col md={6}>
            <IntroCompanies>
              <p>For the past 9 years I’ve been working with clients such as <strong>Nike</strong> <span>~</span> <strong>Louis Vuitton</strong> <span>~</span> <strong>Marvel</strong> <span>~</span> <strong>DNB Bank</strong> <span>~</span> <strong>Indeed</strong> <span>~</span> <strong>Aaptiv</strong> and agencies including <strong>Accept &amp; Proceed</strong> <span>~</span> <strong>Metalab</strong> <span>~</span> <strong>Fantasy</strong> <span>~</span> <strong>Platform</strong>.</p>
            </IntroCompanies>
          </Col>
        </Row>
      </Container>
    </IntroWrap>
  );
};

export default Intro;